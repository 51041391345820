const intToWordsMain = (k, d) => { // целое число прописью, это основа
  let i = '',
    e = [
      ['', 'тысяч', 'миллион', 'миллиард', 'триллион', 'квадриллион', 'квинтиллион', 'секстиллион', 'септиллион',
        'октиллион', 'нониллион', 'дециллион'
      ],
      ['а', 'и', ''],
      ['', 'а', 'ов']
    ];
  if (k === '' || parseInt(k,10) === 0) {
    return ' ноль';
  } // 0
  k = k.split(/(?=(?:\d{3})+$)/); // разбить число в массив с трёхзначными числами
  if (k[0].length === 1) k[0] = '00' + k[0];
  if (k[0].length === 2) k[0] = '0' + k[0];
  for (let j = (k.length - 1); j >= 0; j--) { // соединить трёхзначные числа в одно число, добавив названия разрядов с окончаниями
    if (k[j] !== '000') {
      i = (((d && j === (k.length - 1)) || j === (k.length - 2)) && (k[j][2] === '1' || k[j][2] === '2') ? intToWordsChangeDigitPlace(k[j], 1) : intToWordsChangeDigitPlace(k[j])) + intToWordsDeclOfNum(k[j], e[0][k.length - 1 - j], (j === (k.length - 2) ? e[1] : e[2])) + i;
    }
  }
  return i;
};
const intToWordsChangeDigitPlace = (k, d) => { // преобразовать трёхзначные числа
  let e = [
    ['', ' один', ' два', ' три', ' четыре', ' пять', ' шесть', ' семь', ' восемь', ' девять'],
    [' десять', ' одиннадцать', ' двенадцать', ' тринадцать', ' четырнадцать', ' пятнадцать', ' шестнадцать',
      ' семнадцать', ' восемнадцать', ' девятнадцать'
    ],
    ['', '', ' двадцать', ' тридцать', ' сорок', ' пятьдесят', ' шестьдесят', ' семьдесят', ' восемьдесят',
      ' девяносто'
    ],
    ['', ' сто', ' двести', ' триста', ' четыреста', ' пятьсот', ' шестьсот', ' семьсот', ' восемьсот',
      ' девятьсот'
    ],
    ['', ' одна', ' две']
  ];
  return e[3][k[0]] + (k[1] === '1' ? e[1][k[2]] : e[2][k[1]] + (d ? e[4][k[2]] : e[0][k[2]]));
};
const intToWordsDeclOfNum = (n, t, o) =>{ // склонение именительных рядом с числительным: число (typeof = string),
                                          // корень (не
                                          // пустой), окончание
  let k = [2, 0, 1, 1, 1, 2, 2, 2, 2, 2];
  return (t === '' ? '' : ' ' + t + (n[n.length - 2] === "1" ? o[2] : o[k[n[n.length - 1]]]));
};

export default {
  timeFromXtoDateView: (value) => {
    if (value === undefined) { return ''; }
    let arrValue = value.split('T');
    let arrDate = arrValue[0].split('-');
    return arrDate[2] + '.' + arrDate[1] + '.' + arrDate[0];
  },
  timeFromXtoDateView2: (value) => {
    if (value === undefined) return '';
    return value.toString().replace(/(\d{4}).(\d{2}).(\d{2})/, '$3.$2.$1');
  },
  timeFromXtoDateView3: (value) => {
    if (value === undefined) { return ''; }
    let arrValue = value.split('T');
    let arrDate = arrValue[0].split('-');
    let arrTime = arrValue[1].split(':');
    return arrDate[2] + '.' + arrDate[1] + '.' + arrDate[0] + ' ' + arrTime[0] + ':' + arrTime[1];
  },
  dmY:(value)=> {
    if (value === undefined) { return ''; }
    try {
      let arrValue = value.split('T');
      let arrDate = arrValue[0].split('-');
      return arrDate[2] + '.' + arrDate[1] + '.' + arrDate[0];
    } catch (e) {
      return '-';
    }
  },
  dmY_mysql:(value)=> {
    if (value === undefined) { return ''; }
    try {
      let arrValue = value.split(' ');
      let arrDate = arrValue[0].split('-');
      return arrDate[2] + '.' + arrDate[1] + '.' + arrDate[0];
    } catch (e) {
      return '-';
    }
  },
  dmY_HIS_mysql:(value)=> {
    if (value === undefined) { return ''; }
    try {
      const ORIGINAL_DATE = new Date(value);
      let [day, month, year, hours, minutes] = [
        ORIGINAL_DATE.getDate(),
        ORIGINAL_DATE.getMonth(),
        ORIGINAL_DATE.getFullYear(),
        ORIGINAL_DATE.getHours(),
        ORIGINAL_DATE.getMinutes()
      ].map(i => String(i).padStart(2, '0'));
      return `${ day }.${ month }.${ year } ${ hours }:${ minutes }`;
    } catch (e) {
      return '-';
    }
  },
  dmY_His:(value) => {
    if (value === undefined) { return ''; }
    try {
      let arrValue = value.split('T');
      let arrDate = arrValue[0].split('-');
      let arrTime = arrValue[1].split(':');
      return arrDate[2] + '.' + arrDate[1] + '.' + arrDate[0] + ' ' + arrTime[0] + ':' + arrTime[1];
    } catch (e) {
      return '--';
    }

  },
  dmY_time:(value) => {
    if (value === undefined) { return ''; }
    try {
      let arrValue = value.split(' ');
      let arrDate = arrValue[0].split('-');
      let arrTime = arrValue[1].split(':');
      return arrDate[2] + '.' + arrDate[1] + '.' + arrDate[0] + ' ' + arrTime[0] + ':' + arrTime[1];
    } catch (e) {
      return '--';
    }

  },
  moneyIntlFormat: (value, option = {style: "currency", currency: "RUB"}) => {
    return  new Intl.NumberFormat("ru-RU", option).format(value);
  },
  moneyFormat: (value) => {
    if (value === undefined) { return ''; }
    let megaxren = value.toString().split('.');
    try {
      if (megaxren[1] != undefined) {
        if (megaxren[1].length == 1 ) {
          value = megaxren[0] + '.' + megaxren[1] + '0';
        }
      }

    }catch (e) {
       console.log(e)
     }
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") + '<span class="ALS">a</span>';
  },
  humanViewPhone: (value) => {
    try {

      if (value === undefined) { return ''; }
      return value.toString().replace(/(\d{3})(\d{3})(\d{2})(\d{2})/, '+7 ($1) $2-$3-$4');
    } catch (e) {
      console.log(e);
      return 'номер не корректный'
    }

  },
  convertSecondsinMinAndSeconds: (value) => {
    if (value === undefined) { return ''; }
    let mins = Math.floor(value / 60);
    let secs = Math.floor(value - mins * 60);
    return mins + ':' + secs;
  },
  checkbox:(value)=>{
    if (value === true) {
      return  '<input type="checkbox" checked disabled class="checkboxDynamic">';
    } else {
      return  '<input type="checkbox" disabled class="checkboxDynamic">';
    }
  },
  intToWords(a) {
    a = Number(a).toFixed(2).split('.'); // округлить до сотых и сделать массив двух чисел: до точки и после неё
    let out = intToWordsMain(a[0],0) + intToWordsDeclOfNum(a[0], 'рубл', ['ь', 'я', 'ей']);
    if (a[1] !== undefined && a[1].length > 0) {
      out += intToWordsMain(a[1],1) + intToWordsDeclOfNum(a[1], 'копе', ['йка', 'йки', 'ек']);
    }
    return out[1].toUpperCase() + out.substring(2);
  },
  shortFio(fio){
    if (typeof fio === 'string' && fio.length > 0){
      let arrFio = fio.split(' ');
      if (arrFio.length === 2){
        return arrFio[0] + ' ' + arrFio[1][0] + '.'
      } else if (arrFio.length === 3){
        return arrFio[0] + ' ' + arrFio[1][0] + '. '+arrFio[2][0]+'.';
      } else {
        return fio;
      }
    } else {
      return fio;
    }
  },
  numberToSting(number) {
    if(typeof number !== 'number') return
    return number.toString().replace('.', ',')
  }
}
